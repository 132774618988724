.gjesteliste {
  width: 80%;
  max-width: 550px;
  margin: 0 auto;
}

.gjesteliste-accordion {
  width: 100%;
}

.gjesteliste-accordion-item {
  margin-top: 1rem;
}

h1 {
  margin-top: 5rem;
  margin-bottom: 3rem;
  text-align: center;
}

.gjesteliste ul {
  margin: 0;
  padding: 0;
}

.gjesteliste ul li {
  list-style-type: none;
  margin: 0 auto;
  width: 100%;
}