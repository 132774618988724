.hjem {
  text-align: center;
  margin: 0 auto;
  max-width: 550px;
  margin-top: 2rem;
}

#hjem-bilde {
  position: fixed;
  left: 1rem;
  top: 0.7rem;
  z-index: 2;
  height: 48px;
  width: 48px;
}

#nicoline-bilde {
  margin-top: 3rem;
  max-width: 75%;
  border-radius: 3%;
}

#hjem-overskrift {
  line-height: 1.8;
  margin-top: 2rem;
}
