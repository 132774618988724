.register-page {
  max-width: 550px;
  margin: 0 auto;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.nicolinebilde {
  width: 30%;
  height: 30%;
  margin-top: 2rem auto;
  text-align: center;
  border-radius: 50%;
  animation: rotation 2s infinite linear;
}

.form-gruppa,
.registrer-btn {
  width: 75%;
  margin: 5% 11%;
}

.nicoline-form {
  width: 100%;
  margin: 2rem auto;
}

.marginated {
  margin: 2px;
}

.bolded {
  font-weight: bold;
}

.registrert {
  margin: 2.5rem 0;
  padding: 1rem;
  background-color: #376a37;
  color: white;
  font-size: 18px;
  text-align: center;
}

.has-sent {
  padding-bottom: 1rem;
}
