.omoss-site {
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
}

.om-person {
  padding: 1rem;
  margin-top: 2rem;
  border-radius: 1%;
  background-color: white;
}

#nicolai-bilde,
#line-bilde,
#motes-bilde {
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
}

.bilde-wrapper {
  text-align: center;
}

.person-tekst {
  margin-top: 1rem;
}

.motet-deres {
  padding: 1rem;
  margin-top: 2rem;
  margin-bottom: 4rem;
  border-radius: 1%;
  background-color: white;
}

.motet-tekst {
  margin-top: 1rem;
}
