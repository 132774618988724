.nicoline-hamburger {
  position: fixed;
  right: 1rem;
  top: 0.7rem;
  z-index: 3;
}

.meny {
  text-align: center;
  list-style-type: none;
  position: fixed;
  z-index: 2;
  top: 0px;
  font-size: 1.5rem;
  width: 100%;
  height: 100%;
  padding: 10% 0;
  margin: 0 auto;
  background-color: #ecdbb7;
}

ul li {
  margin: 1rem;
  color: black;
}

ul a {
  color: black;
  display: block;
}

@media (min-width: 762px) {
  ul li {
    display: inline-block;
  }
}

.hjemknapp {
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 1.2rem;
  left: 1rem;
  z-index: 3;
}
