.bidrag-site {
  max-width: 600px;
  margin: 0 auto;
}

.bidrag-info {
  margin: 4rem 2rem;
  background-color: white;
  padding: 2rem;
  border-radius: 2%;
}

.bidrag-bilde {
  width: 100%;
  max-width: 100%;
}
