.helg-page {
  max-width: 1000px;
  margin: 0 auto;
}

.helg-intro {
  margin: 3rem 2rem 2rem 2rem;
  border-radius: 1%;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.95);
  text-align: center;
}

.gard-intro {
  text-align: center;
  margin: 2rem;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 2rem;
  border-radius: 1%;
}

.dag-intro {
  text-align: left;
  margin: 0 2rem;
  background-color: white;
  padding: 2rem 2rem 5px 2rem;
  border-radius: 1%;
}

.dag-intro-tekst {
  margin-bottom: 0;
}

.gard-bilde {
  width: 100%;
  height: 100%;
  border-radius: 1%;
  margin: 1rem auto 0 auto;
}

.gard-accordion {
  background-color: #ecdbb7;
  border: none;
  margin-bottom: 1.5rem;
}

.gard-accordion button,
.gard-accordion {
  background-color: #ecdbb7;
}
