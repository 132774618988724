.forlovere-hoved {
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
}

.forlover {
  padding: 1rem;
  border-radius: 3%;
  background-color: white;
}

.forlover-tekst {
  margin-top: 1rem;
}

.om-forlover {
  padding: 1rem;
  border-radius: 3%;
  background-color: white;
}

.forlover-bilder {
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
}

#forlover-bottom {
  margin-bottom: 12rem;
}

.bilde-wrapper {
  text-align: center;
}
