.onskeliste {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.onske {
  width: 90%;
  height: 25%;
  margin: 3rem auto;
  border-radius: 2%;
  background-color: white;
  padding: 1.5rem 0;
}

.onske-bilde {
  width: 38%;
  height: 50%;
  max-width: 500px;
}

.onske-tekst {
  vertical-align: middle;
  margin: 0.5rem auto;
  width: 80%;
}

.onske-tekst a {
  color: black;
}
