.bordplassering-accordion {
  width: 100%;
}

#sokefelt {
  background-color: white;
}

.sokefelt-wrapper,
.bordplassering-accordion-item,
.bordplassering-info {
  max-width: 700px;
  margin: auto;
}

.bordplassering-info {
  margin-bottom: 0.5rem;
}
