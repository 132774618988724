.bygging {
  width: 80%;
  max-width: 550px;
  margin: 0 auto;
}

.bygging h1 {
  margin-bottom: 3rem;
}

.tidspunkt-boks {
  background-color: white;
  padding: 1rem 1rem 0.5rem 1rem;
  margin: 0 auto;
  border-radius: 1%;
  max-width: 550px;
}

.tidspunkt-boks h3 {
  margin-bottom: 1rem;
}

.tidspunkt-paragraf {
  margin-top: 0.5rem;
}

.bygge-bilder {
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  border-radius: 1%;
}
